export function Footer() {
  return (
    <div className="col-12 mt-4">
      <p>
        Bei Fragen bezüglich Inhaltsstoffen und Allergenen stehen wir Ihnen gerne zur Verfügung.
      </p>
      <p className="text-center mt-2 mb-3">
        <a
          href="https://gb-schweinberg.de/impressum/"
          className="text-decoration-none"
          style={{ color: "#505050" }}>
          <small>Impressum</small>
        </a>
      </p>
    </div>
  );
}
