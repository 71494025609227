import "./App.css";
import { Header } from "./Header";
import { Dish } from "./Dish";
import { Footer } from "./Footer";
import data from "./data.json";

function App() {
  return (
    <div className="container">
      <div className="row d-flex justify-content-center flex-wrap">
        <div className="col-lg-8">
          <Header />
          <div className="col-12 text-center">
            <h1 className="mt-5 mb-5">Speisekarte Abholservice</h1>
          </div>
          <div>
            {data.Dishes.map((dish, i) => {
              return (
                <div key={i}>
                  <div>
                    <Dish title={dish.title} description={dish.description} price={dish.price} />
                    <p></p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="row d-flex justify-content-center flex-wrap">
        <div className="col-lg-8">
          <div className="col-12 text-center">
            <h2 className="mt-4 mb-4">Nach Lust und Laune ergänzen</h2>
          </div>
          <div>
            {data.SideDishes.map((sideDish, i) => {
              return (
                <div key={i}>
                  <div>
                    <Dish
                      title={sideDish.title}
                      description={sideDish.description}
                      price={sideDish.price}
                    />
                    <p></p>
                  </div>
                </div>
              );
            })}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
