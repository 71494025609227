import { FaPhone, FaWhatsapp } from "react-icons/fa";

export function Header() {
  return (
    <>
      <div className="col-12 d-lg-none">
        <img
          src="https://customer-ci.ne-dsgn.de/gbs/gbs-wort-bildmarke-pos.svg"
          alt="Logo"
          className="mt-5 mb-5 mx-auto d-block"
        />
      </div>
      <div className="col-lg-12 d-none d-lg-block">
        <img
          src="https://customer-ci.ne-dsgn.de/gbs/gbs-wort-bildmarke-pos.svg"
          alt="Logo"
          className="mt-5 mb-5 mx-auto d-block"
          style={{ width: "80%", height: "auto" }}
        />
      </div>
      <div className="col-12">
        <p>Gerne nehmen wir Ihre Bestellung per Telefon und zusätzlich per WhatsApp an.</p>
        <p>
          <FaPhone className="mr-3" />
          <a href="tel:+4962836203" className="text-decoration-none">
            06283/6203
          </a>
        </p>
        <p>
          <FaWhatsapp className="mr-3" />
          <a href="tel:+4917641594301" className="text-decoration-none">
            0176/41594301
          </a>
        </p>
        <hr />
        <p>
          <b>Öffnungszeiten</b>
        </p>
        <p>
          Freitag, Samstag
          <br />
          17:00 Uhr - 20:00 Uhr
        </p>
        <p>
          Sonntag
          <br />
          11:30 Uhr - 14:00 Uhr und
          <br />
          17:00 Uhr - 20:00 Uhr
        </p>
      </div>
    </>
  );
}
