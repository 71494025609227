export function Dish(props) {
  return (
    <>
      <div className="col-12 align-items-baseline d-flex">
        <p className="title">{props.title}</p>
        <span className="dots"></span>
        <p className="price">{props.price}</p>
      </div>
      <div className="col-12 mb-3">
        <p>{props.description}</p>
      </div>
    </>
  );
}
